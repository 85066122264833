class Reorder extends HTMLElement {
  constructor() {
    super();
    this.reorderButton = this.querySelector(".reorder__button");
    if (this.reorderButton) {
      this.reorderButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.reorderProduct();
      });
    } else {
      const mainReorderButtons = this.querySelectorAll(".main-reorder__button");
      if (mainReorderButtons.length) {
        mainReorderButtons.forEach((button) => {
          button.addEventListener("click", (e) => {
            e.preventDefault();
            this.reorderFromButton(button);
          });
        });
      }
    }
  }
  getOrderData() {
    this.orderData = this.orderData || JSON.parse(this.querySelector('[type="application/json"]').textContent);
    return this.orderData;
  }
  async reorderProduct() {
    const orderData = this.getOrderData();
    if (!orderData) {
      console.error("No order data available");
      return;
    }
    const data = {
      items: orderData.map((item) => ({
        quantity: item.quantity,
        id: item.variant_id,
        selling_plan: item.selling_plan_id || null
      }))
    };
    try {
      const result = await addItem(data);
      console.log("Reorder result:", result);
      window.location.href = "/checkout";
    } catch (error) {
      console.error("Error reordering product:", error);
    }
  }
  async reorderFromButton(button) {
    const orderItemsData = button.getAttribute("data-order-items");
    if (!orderItemsData) {
      console.error("No order data found on the button");
      return;
    }
    try {
      const orderData = JSON.parse(orderItemsData);
      const data = {
        items: orderData.map((item) => ({
          quantity: item.quantity,
          id: item.id,
          selling_plan: item.selling_plan
        }))
      };
      console.log("Reordering with data:", data);
      const result = await addItem(data);
      console.log("Reorder result:", result);
      window.location.href = "/checkout";
    } catch (error) {
      console.error("Error processing order data:", error);
    }
  }
}
customElements.define("reorder-block", Reorder);
async function addItem(data) {
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    const response = await fetch("/cart/add.js", params);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error adding item to cart:", error);
    throw error;
  }
}
